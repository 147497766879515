import {
    firebase,
    db,
    GET,
    ADD
} from '@/firebase.js'

import Loading from '@/components/Loading/Loading.vue'

export default {
    name: 'Login',
    data: function () {
        return {
            showGoogleLoadingOverlay: false
        }
    },
    components: {
        Loading,
    },
    methods: {
        hideLogin: function () {
            let query = {
                login: null
            }
            if (this.$route.query.event && this.$route.query.event !== 'undefined') {
                this.$router.replace({
                    query,
                    path: '/' + this.$route.query.event
                })
                //location.reload()
            } else {
                this.$router.replace({
                    query,
                })
            }
        },
        loginWithGoogle: async function () {
            let newQuery = {
                type: 'gauth'
            }
            const query = {
                ...this.$route.query,
                ...newQuery
            };
            this.$router.replace({
                query
            });
            this.showGoogleLoadingOverlay = true
            let provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');
            provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
            firebase.auth().signInWithRedirect(provider).then(async result => {
                // The signed-in user info.
                console.log(result)
                if (result.user) {
                    let email = result.user.email;
                    if (!email) email = result.additionalUserInfo.profile.email
                    let exists = await this.checkIfUserExists(email)
                    this.showGoogleLoadingOverlay = false
                    if (exists) this.hideLogin()
                    else this.addGoogleUserThenRedirect(email)
                }
            }).catch((err) => {
                this.showGoogleLoadingOverlay = false
                alert(err)
            });
        },
        checkIfAuthIsRedirect: function () {
            if (this.$route.query.type === 'gauth') {
                this.showGoogleLoadingOverlay = true
                firebase.auth().onAuthStateChanged(async user => {
                    let email = (user.email) ? user.email : user.providerData[0].email
                    let exists = await this.checkIfUserExists(email)
                    this.showGoogleLoadingOverlay = false
                    if (exists) this.hideLogin()
                    else this.addGoogleUserThenRedirect(email)
                })
            }
        },
        addGoogleUserThenRedirect: function (email) {
            let user = firebase.auth().currentUser;
            let col = db.collection('users')
            ADD(col, {
                email: email,
                uid: user.uid,
                verified: true,
                auth: 'Google',
                date: new Date(),
                origin: 'app'
            }).then(doc => {
                user.updateProfile({
                    displayName: doc.id,
                })
                this.addSubcriberToMC(doc, email)
            })
        },
        addSubcriberToMC: function (doc, email) {
            console.log('Sending email to mc')
            let addMCSubscriber = firebase.functions().httpsCallable('addMCSubscriber');
            addMCSubscriber({
                email: email
            }).then(result => {
                console.log(result)
                this.$router.push({
                    path: '/auth?uid=' + doc.id + '&email=' + email
                })
            }).catch(err => {
                console.log(err.code)
            })
        },
        checkIfUserExists: function (email) {
            return new Promise(resolve => {
                let getUserExistence = firebase.functions().httpsCallable('getUserExistence');
                getUserExistence({
                    email: email
                }).then(result => {
                    console.log(result)
                    resolve(result.data.exists)
                });
            })
        },
        getUser: function (email) {
            return new Promise(resolve => {
                let col = db.collection('users').where('email', '==', email)
                GET(col).then((users) => {
                    if (users.empty) resolve(null)
                    else resolve(users.docs[0])
                })
            })
        }
    },
    created: function () {
        this.checkIfAuthIsRedirect()
    }
}