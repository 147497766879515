const User = {
    install(Vue) {
        Vue.observable({
            $user: {}
        });
        Vue.prototype.$setUser = (user) => {
            let email = (user.email) ? user.email : user.providerData[0].email
            let provider = (user.providerData[0].providerId === 'google.com') ? 'google' : 'email'
            Vue.prototype.$user = {
                uid: user.uid,
                displayName: user.displayName,
                email: email,
                provider: provider
            }
        }
        Vue.prototype.$clearUser = () => {
            Vue.prototype.$user = {}
        }
    }
}

export default User