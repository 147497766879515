import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import VueMeta from 'vue-meta'
import infiniteScroll from 'vue-infinite-scroll'
import GAuth from 'vue-google-oauth2'
import Event from './assets/js/event.js'
import User from './assets/js/user.js'
// import VueGtag from "vue-gtag";
import VueDragscroll from 'vue-dragscroll'
import VueGapi from 'vue-gapi'
import "@lottiefiles/lottie-player";

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/goodkit/theme.min.css';
import './assets/goodkit/theme-sans-serif.min.css';

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);
Vue.use(Event)
Vue.use(User)

const gauthOption = {
  clientId: '794964390607-qoeprkf9fiibqqvkrqagkpghgrahsc6n.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)

Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(infiniteScroll)
Vue.use(VueDragscroll)

Vue.use(VueGapi, {
  apiKey: 'AIzaSyCZKRU9FzCoNSsqO2qwwDVFXVD9mWYQh7s',
  clientId: '794964390607-qoeprkf9fiibqqvkrqagkpghgrahsc6n.apps.googleusercontent.com',
  discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
  scope: 'https://www.googleapis.com/auth/spreadsheets',
  refreshToken: true,
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')