import RadialProgressBar from 'vue-radial-progress'


export default {
    name: 'Tooltip',
    components: {
        RadialProgressBar
    },
    props: {
        title: String,
        delay: Number,
        dir: String,
        closeIcon: {
            default: false,
            type: Boolean
        },
        hide: Boolean,
        timer: Number,
        id: String,
        closeCB: Function
    },
    data: function () {
        return {
            animationDelay: 0,
            loaded: false,
            timerDone: 0,
        }
    },
    methods: {
        setAnimationDelay: function () {
            if (!this.delay) return this.loaded = true
            this.animationDelay = (this.delay / 1000) + 's'
            this.loaded = true
        },
        closeTooltip: function () {
            let id = this.id ? this.id : this.title
            localStorage.setItem('tooltip-' + id, true)
            this.hide = true
            if (this.closeCB) this.closeCB()
        },
        setTimer: function () {
            setTimeout(() => {
                this.timerDone = 1
            }, 10)
            setTimeout(() => {
                this.closeTooltip()
            }, this.timer)
        }
    },
    created: function () {
        this.setAnimationDelay()
        if (this.timer) this.setTimer()
    }
}