import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/auth',
  name: 'Auth',
  component: () => import('../views/Auth/Auth.vue')
}, {
  path: '/profile',
  name: 'Profile',
  component: () => import('../views/Profile/Profile.vue')
}, {
  path: '/badge/:id',
  name: 'Badge',
  components: {
    overview: () => import('../views/Badge/Badge.vue')
  }
}, {
  path: '*/rankings',
  name: 'Rankings',
  component: () => import('../views/Rankings/Rankings.vue')
}, {
  path: '*/ranking/:id',
  name: 'Ranking',
  components: {
    overview: () => import('../views/Ranking/Ranking.vue')
  }
}, {
  path: '*/companies',
  name: 'Companies',
  component: () => import('../views/Companies/Companies.vue')
}, {
  path: '*/company/:id',
  name: 'Company',
  components: {
    overview: () => import('../views/Company/Company.vue')
  }
}, {
  path: '*/article/:id',
  name: 'Article',
  components: {
    overview: () => import('../views/Article/Article.vue')
  }
}, {
  path: '*/badges',
  name: 'Badges',
  component: () => import('../views/Badges/Badges.vue')
}, {
  path: '/init',
  name: 'Init',
  component: () => import('../views/Init/Init.vue')
}, {
  path: '/confirm',
  name: 'Confirm',
  component: () => import('../views/Confirm/Confirm.vue')
}, {
  path: '/admin',
  name: 'Admin',
  component: () => import('../views/Admin/Admin.vue')
}, {
  path: '/admin/org/:id',
  name: 'CompanyAdmin',
  component: () => import('../views/Admin/Company/Company.vue')
}, {
  path: '/admin/company-creator',
  name: 'CompanyCreator',
  component: () => import('../views/Admin/CompanyCreator.vue')
}, {
  path: '/admin/event-creator',
  name: 'EventCreator',
  component: () => import('../views/Admin/EventCreator.vue')
}, {
  path: '/admin/syncer',
  name: 'Syncer',
  component: () => import('../views/Admin/Syncer.vue')
}, {
  path: '/:eid',
  name: 'Home',
  component: () => import('../views/Home/Home.vue')
}, {
  path: '/',
  name: 'Start',
  component: () => import('../views/Start/Start.vue')
}]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      console.log(savedPosition)
      setTimeout(() => {
        window.scrollTo(0, savedPosition.y)
      }, 10)
      return savedPosition
    }
  },
})

export default router