import {
    firebase,
    GET,
    db,
} from '@/firebase.js'

import Vue from 'vue'

import Menu from '@/components/Menu/Menu.vue'
import Login from '@/components/Login/Login.vue'
import Naver from '@/components/Naver/Naver.vue'
import Cookie from '@/components/Cookie/Cookie.vue'
import Cell from '@/components/Cell/Cell.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import VueAnalytics from "vue-analytics";
import router from './router'

export default {
    name: "App",
    metaInfo() {
        return {
            title: "Gokind - Uncover, Compare, Connect",
            icon: require('@/assets/img/meta/favicon-light.png'),
            meta: [{
                    vmid: 'keywords',
                    name: 'keywords',
                    content: 'Gokind, Sustainability, CSR, Equality, App, Jobs, Armada'
                }, {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: "Gokind - Uncover, Compare, Connect"
                }, {
                    vmid: 'description',
                    name: 'description',
                    content: 'Discover how the companies attending THS Armada compares regarding sustainability.'
                }, {
                    vmid: 'og:description',
                    name: 'og:description',
                    content: 'Discover how the companies attending THS Armada compares regarding sustainability.'
                }, {
                    vmid: 'og:image',
                    name: 'og:image',
                    content: require('@/assets/img/meta/og-image-new.png')
                }, {
                    vmid: 'og:logo',
                    name: 'og:logo',
                    content: require('@/assets/img/meta/favicon-light.png')
                },
                {
                    property: 'og:site_name',
                    content: 'Gokind App'
                },
                {
                    property: 'og:type',
                    content: 'website'
                }
            ]
        }
    },
    components: {
        Menu,
        Login,
        Naver,
        Cookie,
        Cell,
        Skeleton,
        Tooltip
    },
    data: function () {
        return {
            userLoaded: false,
            eventLoaded: false,
            loaded: false,
            transitionName: '',
            transitionMode: '',
            showNaver: false,
            history: [],
            userIsSignedIn: false,
            popStateDetected: false,
            promptCookies: false,
            showEventController: false,
            events: [],
            switcherOverlayTitle: null,
            windowHeight: null,
            production: (process.env.NODE_ENV === 'production'),
            coldProduction: (window.location.hostname === 'gokind.app'),
            activeEvent: 'armada',
            instantLoadPages: ['Start', 'Auth', 'Admin', 'Init', 'Profile', 'Confirm', 'CompanyCreator', 'EventCreator', 'Syncer', 'Articles'],
        }
    },
    methods: {
        closeEventSwitcher: function () {
            this.switcherOverlayTitle = null
        },
        eventSwitcherTimer: function () {
            if (!localStorage.getItem('tooltip-switcher')) {
                setTimeout(() => {
                    this.switcherOverlayTitle = 'Switch here'
                }, 3000)
            }
            // After two minutes
            setTimeout(() => {
                this.switcherOverlayTitle = 'Explore more'
            }, 120000)
        },
        setActiveEvent: function (event) {
            if (!event.released && this.coldProduction) return
            let suffix = (this.$route.matched[0].path.split('*')[1]) ? this.$route.matched[0].path.split('*')[1] : ''
            this.$router.replace({
                path: '/' + event.eid + suffix
            })
            //location.reload()
            this.loaded = false
            this.getEvent(event.eid)
        },
        getEvents: function () {
            this.showEventController = true
            if (this.events.length > 0) return
            let col = db.collection('events')
            col = col.where('show', '==', true)
            col = col.orderBy('created', 'desc')
            GET(col).then(events => {
                events.forEach(async event => {
                    this.events.push({
                        name: event.data().name,
                        eid: event.data().eid,
                        desc: event.data().desc,
                        released: event.data().released
                    })
                })
                this.events.sort(function (x, y) {
                    return y.released - x.released
                });
            })
        },
        addMissingDisplayName: function (user) {
            if (user.email === 'superadmin@gokind.co') return
            let col = db.collection('users').where('uid', '==', user.uid)
            GET(col).then(users => {
                console.log(users)
                user.updateProfile({
                    displayName: users.docs[0].id,
                })
            })
        },
        auth: function () {
            firebase.auth().onAuthStateChanged(user => {
                this.userIsSignedIn = user ? true : false
                if (user) {
                    // User is signed in.
                    console.log('User is signed in', user)
                    this.$setUser(user)
                    if (!user.displayName) this.addMissingDisplayName(user)
                } else {
                    // No user is signed in.
                    console.log('User is not signed in')
                    //this.checkIfUserHasBeenHereBefore()
                    if (this.$user.uid) {
                        setTimeout(() => {
                            this.$clearUser()
                        }, 1000)
                    }
                }

                this.userLoaded = true
                if (this.eventLoaded) this.loaded = true
                this.$forceUpdate()
            });
        },
        getEvent: function (id) {
            this.$getEvent(id).then(response => {
                if (!this.$event.released && this.coldProduction) return this.backToStart()
                this.eventLoaded = true
                if (this.userLoaded) this.loaded = true
                this.$forceUpdate()
                console.log(response)
                this.cookieChecker()
            })
        },
        setPathMatch: function () {
            this.loaded = false
            let path = this.$route.params.pathMatch
            if (path !== undefined && path !== null && path !== '' && path.length !== 0) this.getEvent(path.split('/')[1])
            else if (this.$route.params.eid) this.getEvent(this.$route.params.eid)
        },
        pathIsOverlay: function (path) {
            return (path === 'Company' || path === 'Badge' || path === 'Ranking' || path === 'Profile' || path === 'Article')
        },
        setNaverVisbility: function (route) {
            if (!route) route = this.$route
            if (route.query.hideNav === 'true') return this.showNaver = false
            let path = route.name
            this.showNaver = (path === 'Company' || path === 'Badge' || path === 'Ranking' || path === 'Profile' || path === 'Article')
        },
        setTransition: function (to, from) {
            if (this.pathIsOverlay(from.name) && !this.pathIsOverlay(to.name)) {
                this.transitionName = 'left-slide-out'
                this.transitionMode = 'in-out'
            } else {
                this.transitionName = ''
                this.transitionMode = ''
            }
            if (this.pathIsOverlay(to.name) && !to.query.bt) {
                this.transitionName = 'left-slide'
                this.transitionMode = 'in-out'
            }
            console.log('Transition set to', this.transitionName, this.transitionMode)
        },
        iOS: function () {
            return [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                ||
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        },
        cookieChecker: function () {
            if (!localStorage.getItem('footprint') && this.production) {
                this.promptCookies = true
                let addEventVisitor = firebase.functions().httpsCallable('addEventVisitor');
                addEventVisitor({
                    eventId: this.$event.id,
                }).then(result => {
                    console.log(result)
                });
            } else {
                console.log('User has been here before')
                this.acceptCookies()
            }
        },
        acceptCookies: function () {
            localStorage.setItem('footprint', true)
            this.promptCookies = false
            Vue.use(VueAnalytics, {
                id: 'UA-175676214-1',
                router
            })
        },
        declineCookies: function () {
            localStorage.setItem('footprint', true)
            this.promptCookies = false
        },
        isFacebookApp: function () {
            var ua = navigator.userAgent || navigator.vendor || window.opera;
            return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf('Instagram') > -1);
        },
        getScrollToTopDelay: function (to, from) {
            if (!this.pathIsOverlay(from.name) && !this.pathIsOverlay(to.name) && (from.name !== to.name)) return 100
            // else if (this.pathIsOverlay(to.name) && !this.pathIsOverlay(from.name)) return 100
            else if ((from.name === 'Rankings' && to.name === 'Ranking') || (from.name === 'Badges' && to.name === 'Badge')) return 100
            else return null
        },
        backToStart: function () {
            this.$clearEvent()
            this.$router.push({
                path: '/'
            })
        }
    },
    watch: {
        '$route'(to, from) {
            // this.setTransition(to, from)
            this.setNaverVisbility(to)
            if (!to.query.bt) this.history.unshift(from)
            let scrollToTopDelay = this.getScrollToTopDelay(to, from)
            if (scrollToTopDelay) {
                console.log('Scroll to top')
                setTimeout(() => {
                    window.scrollTo(0, 0)
                }, scrollToTopDelay)
            }
        },
        '$route.params.pathMatch': function () {
            if (!this.$event.id) {
                this.setPathMatch()
                this.eventSwitcherTimer()
            }
        },
        '$route.params.eid': function () {
            if (!this.$event.id) this.setPathMatch()
            else if (this.$event.eid !== this.$route.params.eid && this.$route.params.eid) {
                this.loaded = false
                console.log(this.loaded)
                this.setPathMatch()
            }
        },
        '$route.query.event': function () {
            console.log('EVENT', this.$route.query.event)
            if (!this.$event.id && this.$route.query.event) this.getEvent(this.$route.query.event)
        },
        '$route.params.id': function () {
            if (!this.$route.query.event) this.loaded = true
        },
        '$route.name': function (name) {
            this.setNaverVisbility(this.$route)
            if (this.$route.params.eid) return
            if (this.instantLoadPages.some(page => page === name)) this.loaded = true
        },
    },
    created: function () {
        this.setPathMatch()
        this.auth()
        this.setNaverVisbility()
        Vue.prototype.$user = {}
        Vue.prototype.$event = {}
    },

    /*     mounted: function () {
            setTimeout(() => {
                const player = document.querySelector("lottie-player");
                player.load(require("@/assets/lottie/discover.json"));
            }, 10);
        }, */
};