export default {
    name: 'Naver',
    props: {
        history: Array,
        fallback: String,
    },
    data: function () {
        return {
            popStateDetected: false
        }
    },
    methods: {
        goBack: function () {
            // Check that last navigation isnt profile or other popups
            console.log(this.history)
            if (this.history.length === 0) return this.goToStartRoute()
            let prevView = this.getPrevViewThatIsDifferent()
            if (!prevView) return this.goToStartRoute()
            console.log(prevView)
            this.$router.go(-(prevView.index))
        },
        goToStartRoute: function () {
            if (this.fallback) {
                console.log('Going to fallback', this.fallback)
                this.$router.push({
                    path: this.fallback
                })
            } else {
                console.log('Going to root')
                this.$router.push({
                    path: '/'
                })
            }
        },
        getPrevViewThatIsDifferent: function () {
            let prevView = null
            let historyCount = 0
            this.history.some(route => {
                historyCount++
                if (route.name !== this.$route.name) return prevView = route
            })
            return {
                index: historyCount,
                view: prevView
            }
        }
    },
    watch: {
        '$route'(to, from) {
            if (this.popStateDetected && to.name === from.name && from.name === 'Company' && to.fullPath.length < from.fullPath.length) {
                this.goBack()
            }
            this.popStateDetected = false
        }
    },
    created: function () {
        window.onpopstate = () => {
            this.popStateDetected = true
        };
    }
}