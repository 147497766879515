<template>
  <div class="mx-5">
    <div v-if="!type">
      <div
        v-for="(n, index) in num"
        :key="index"
        class="cell-card cell-bs"
      ></div>
    </div>
    <div v-if="type === 'Badge'">
      <div class="badge-cell cell-card cell-bs"></div>
      <div class="badge-cell cell-card cell-bs"></div>
    </div>
    <div v-if="type === 'Rank'">
      <div class="rank-cell cell-card cell-bs"></div>
      <div class="rank-cell cell-card cell-bs"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Skeleton",
  props: {
    type: String,
    num: {
      default: 3,
      type: Number,
    },
  },
};
</script>

<style scoped lang="scss">
.cell-card {
  height: 80px;
  border-radius: 6px;
  margin-top: 10px;
  animation: cc-ani 1s alternate-reverse infinite;
  &:nth-child(2) {
    animation-delay: 0.3s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }
  &.badge-cell {
    height: 400px;
    border-radius: 12px;
  }
  &.rank-cell {
    height: 450px;
    border-radius: 12px;
  }
}

@keyframes cc-ani {
  from {
    background-color: white;
  }
  to {
    background-color: rgba(white, 0.2);
  }
}
</style>