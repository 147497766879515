import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
// import 'firebase/analytics'
import {
    validCache
} from '@/assets/js/cacher.js'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyCZKRU9FzCoNSsqO2qwwDVFXVD9mWYQh7s",
    authDomain: "gokind-4f5cd.firebaseapp.com",
    databaseURL: "https://gokind-4f5cd.firebaseio.com",
    projectId: "gokind-4f5cd",
    storageBucket: "gokind-4f5cd.appspot.com",
    messagingSenderId: "794964390607",
    appId: "1:794964390607:web:283284380b25a2e150fdba",
    // measurementId: "G-86M4JVXFZF"
}

firebase.initializeApp(firebaseConfig)
// firebase.analytics();

let cacheBroken = false

function initCache() {
    if (db) return
    // Enables Caching
    firebase.firestore().settings({
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    });
    firebase.firestore().enablePersistence({
        synchronizeTabs: true
    }).then(() => {
        cacheBroken = false
    }).catch(err => {
        cacheBroken = true
        console.log('ERROR:' + err.code)

    });
    db = firebase.firestore()
}

let fetchCount = {}

function setFetchCount(ref) {
    if (!fetchCount[ref]) {
        fetchCount[ref] = {
            count: 1
        }
    } else fetchCount[ref]++
}

const GET = (ref, forceServer = false) => {
    setFetchCount(ref)
    return new Promise((resolve, reject) => {
        let fetchMethod = (validCache(ref) && !forceServer && !cacheBroken && process.env.NODE_ENV !== 'production' &&
            process.env.NODE_ENV !== 'test' &&
            typeof console !== 'undefined') ? 'cache' : 'server'
        ref.get({
            source: fetchMethod // Change source to 'server' to remove caching,
        }).then(data => {
            if (data.empty && data.metadata.fromCache) resolve(GET(ref, true))
            else resolve(data)
        }).catch(err => {
            if (fetchCount[ref].count < 2) {
                console.log("Caching broken, retrieve from server")
                resolve(GET(ref, true))
            } else {
                console.log('Fetching failed', err)
                reject(err)
            }
            reject(err)
        })
    })
}

const UPDATE = (ref, obj) => {
    return new Promise((resolve, reject) => {
        ref.update(obj).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const ADD = (ref, obj) => {
    return new Promise((resolve, reject) => {
        console.log(ref)
        ref.add(obj).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const SET = (ref, obj) => {
    return new Promise((resolve, reject) => {
        console.log(ref)
        ref.set(obj).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const DELETE = (ref) => {
    return new Promise((resolve, reject) => {
        console.log(ref)
        ref.delete().then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

// utils
let db;

initCache()


export {
    initCache,
    db,
    GET,
    ADD,
    UPDATE,
    SET,
    DELETE,
    firebase,
}