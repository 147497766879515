var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"app-desktop-bg"}}),_c('div',{staticClass:"pb-0 pb-sm-3",class:{ 'capped-view': _vm.$route.name !== 'Ranking' },attrs:{"id":"app"}},[_c('div',{attrs:{"id":"top-menu"}}),(
        _vm.$event.name &&
        (_vm.$route.name === 'Badges' ||
          _vm.$route.name === 'Companies' ||
          _vm.$route.name === 'Rankings' ||
          _vm.$route.name === 'Home')
      )?_c('div',[_c('transition',{attrs:{"name":"fade"}},[(_vm.showEventController)?_c('div',{staticClass:"fullscreen-modal-box"},[_c('div',{staticClass:"fullscreen-modal-overlay fade-in-ani",on:{"click":function($event){_vm.showEventController = false}}}),_c('div',{staticClass:"fullscreen-modal-card popup-ani text-left py-6 px-0 rounded"},[_c('h3',{staticClass:"mt-0 mb-6 ml-2 px-5",attrs:{"id":"event-switcher-header"}},[_vm._v(" Pick collection ")]),(_vm.events.length === 0)?_c('Skeleton',{attrs:{"num":3}}):_vm._e(),_vm._l((_vm.events),function(event,index){return _c('div',{key:index,staticClass:"px-5",class:{ 'faded-min cursor-block': !event.released },on:{"click":function($event){return _vm.setActiveEvent(event)}}},[_c('Cell',{staticClass:"mt-3",attrs:{"title":event.name,"desc":event.desc,"active":_vm.$route.params.eid
                    ? event.eid === _vm.$route.params.eid
                    : event.eid === _vm.$event.eid,"badge":!event.released ? 'Upcoming' : null,"type":"select"}})],1)})],2)]):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center",attrs:{"id":"event-switcher-box"}},[_c('button',{staticClass:"mr-4",attrs:{"id":"event-switcher"},on:{"click":function($event){return _vm.getEvents()}}},[_c('i',{staticClass:"ri-arrow-down-s-line"}),_c('span',[_vm._v(" "+_vm._s(_vm.$event.name)+" ")])]),(_vm.switcherOverlayTitle)?_c('Tooltip',{attrs:{"title":_vm.switcherOverlayTitle,"timer":3000,"closeCB":_vm.closeEventSwitcher,"dir":"l","id":"switcher"}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.switcherOverlayTitle)?_c('div',{attrs:{"id":"event-switcher-tooltip-overlay"}}):_vm._e()])],1):_vm._e(),_c('transition',{attrs:{"name":"down"}},[(_vm.showNaver)?_c('Naver',{attrs:{"fallback":'/' + _vm.activeEvent,"history":_vm.history}}):_vm._e()],1),(
        _vm.$route.name !== 'Profile' &&
        _vm.$route.name !== 'Confirm' &&
        _vm.$route.name !== 'Auth' &&
        _vm.$route.name !== 'Init' &&
        _vm.$route.name !== 'Start' &&
        _vm.$route.name !== 'Article'
      )?_c('router-link',{class:{ faded: !_vm.$user.uid && !_vm.userIsSignedIn },attrs:{"to":_vm.$user.uid && _vm.userIsSignedIn
          ? '/profile?event=' + this.$event.eid
          : '?login=true',"tag":"div","id":"nav-profile-icon"}},[_c('i',{staticClass:"ri-account-circle-fill"})]):_vm._e(),(_vm.loaded)?_c('main',[_c('keep-alive',{attrs:{"include":['Ranking', 'BadgeView']}},[_c('router-view',{attrs:{"name":"overview"}})],1),_c('keep-alive',{attrs:{"include":['Badges', 'Home', 'Rankings', 'Companies', 'Start']}},[_c('router-view')],1)],1):_vm._e(),(
        _vm.loaded &&
        _vm.$event &&
        _vm.$event.id &&
        _vm.$route.name !== 'Start' &&
        _vm.$route.name !== 'Admin' &&
        _vm.$route.name !== 'Auth' &&
        _vm.$route.name !== 'Ranking' &&
        _vm.$route.name !== 'Badge' &&
        _vm.$route.name !== 'Company' &&
        _vm.$route.name !== 'Article'
      )?_c('Menu'):_vm._e(),(_vm.$route.query.login)?_c('Login'):_vm._e(),(_vm.promptCookies)?_c('Cookie',{attrs:{"accept":_vm.acceptCookies,"decline":_vm.declineCookies}}):_vm._e(),(
        _vm.loaded &&
        (_vm.$route.name === 'Home' ||
          _vm.$route.name === 'Rankings' ||
          _vm.$route.name === 'Badges' ||
          _vm.$route.name === 'Companies' ||
          _vm.$route.name === 'Start')
      )?_c('p',{staticClass:"disclaimer-text pp-text fade-up-ani input-tooltip text-left mt-n9 mb-11 mx-5"},[_vm._v(" This platform is operated by "),_c('a',{staticClass:"link",attrs:{"href":"https://gokind.co/"}},[_vm._v("Gokind")]),_vm._v(". Data presented has been extracted from public sources and self-reporting; see "),_c('a',{staticClass:"link",attrs:{"href":"https://gokind.app/assets/files/methodology.pdf"}},[_vm._v("methodology")]),_vm._v(". Requests for corrections are lodged "),_c('a',{attrs:{"href":"mailto:studentcouncil@gokind.co"}},[_vm._v("here")]),_vm._v(". Use or depiction of any logotypes, company or brand names and other designations on this platform, are done for information purposes, to improve understanding of this editorial content and comparative analysis of the content of the platform; per recognized fair use/freedom of speech. See "),_c('a',{staticClass:"link",attrs:{"href":"https://gokind.app/assets/files/policy-gokind.pdf"}},[_vm._v("Privacy Policy")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }