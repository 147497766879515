var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"valbox",class:{ 'mr-3': _vm.value.trend === null }},[(
      _vm.value &&
      (_vm.value.current === null ||
        _vm.value.current === undefined ||
        isNaN(_vm.value.current))
    )?_c('div',{staticClass:"mr-4 text-center"},[_c('p',{staticClass:"valbox-value m-0 mb-n2"},[_vm._v("X")]),(_vm.value.unit !== '')?_c('span',{staticClass:"valbox-unit"},[_vm._v("Unpublished")]):_vm._e()]):_vm._e(),(
      _vm.value &&
      _vm.value.current !== null &&
      _vm.value.current !== undefined &&
      !isNaN(_vm.value.current)
    )?_c('div',{staticClass:"mr-4 text-center"},[_c('div',{staticClass:"d-flex mb-n2 justify-content-center mt-n2",class:{ 'faded-value': _vm.value.orderByTrend }},[_c('p',{staticClass:"valbox-value m-0"},[(
            _vm.value.current >= 10 ||
            _vm.value.current === 0 ||
            _vm.value.current.toFixed(1).split('.')[1] === '0'
          )?_c('span',[_vm._v(_vm._s(_vm.value.current.toFixed(0)))]):_c('span',[_vm._v(_vm._s(_vm.value.current.toFixed(1)))])]),(_vm.value.unit !== '')?_c('span',{staticClass:"valbox-unit"},[_vm._v(_vm._s(_vm.value.unit))]):_vm._e()]),(!isNaN(_vm.value.trend) && _vm.value.trend !== null)?_c('span',{staticClass:"badge trend-badge",class:[_vm.getBadgeClasses(), { 'badge-lg': _vm.value.orderByTrend }]},[(_vm.value.trend > 0)?_c('span',[_vm._v("+")]):_vm._e(),(_vm.value.trend)?_c('span',[_vm._v(_vm._s(_vm.value.trend))]):_vm._e(),(!_vm.value.trend)?_c('span',[_vm._v("0")]):_vm._e(),_vm._v("%")]):_vm._e(),(isNaN(_vm.value.trend))?_c('span',{staticClass:"badge trend-badge",class:[_vm.getBadgeClasses(), { 'badge-lg': _vm.value.orderByTrend }]},[_vm._v("-")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }