var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-items-center",class:[_vm.menuType],attrs:{"id":"menu"},on:{"click":function($event){_vm.showTooltip = false}}},[(_vm.showTooltip)?_c('Tooltip',{staticClass:"menu-tooltip",attrs:{"title":'*Tap*',"delay":4000}}):_vm._e(),_vm._l((_vm.tabs),function(tab,index){return _c('router-link',{key:index,staticClass:"menu-item hov-fade",class:{
      faded: _vm.$route.name !== tab.label,
      active: _vm.$route.name === tab.label,
    },attrs:{"tag":"div","to":'/' + _vm.$event.eid + (tab.path === '' ? '' : '/' + tab.path)}},[_c('i',{class:[
        'ri-' +
          (_vm.$route.name === tab.label
            ? tab.icon.selected
            : tab.icon.deselected) ]}),_c('p',[_vm._v(_vm._s(tab.label))])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }