<template>
  <div v-show="lottieLoaded">
    <div class="fullscreen-modal-box">
      <div class="fullscreen-modal-overlay fade-in-ani"></div>
      <div
        class="fullscreen-modal-card popup-ani text-center py-7 px-0 rounded"
      >
        <lottie-player
          background="transparent"
          speed="1"
          style="width: 85%"
          class="mx-auto my-n7"
          loop
          autoplay
        ></lottie-player>
        <h2 class="mt-5 mb-5">Cookie check.</h2>
        <p class="w-75 mx-auto">
          We use cookies to give you the best experience.
          <a
            class="link"
            href="https://gokind.app/assets/files/policy-gokind.pdf"
            >Read more</a
          >.
        </p>
        <button @click="accept" class="btn btn-black btn-block w-75 mx-auto">
          Accept all cookies
        </button>
        <button @click="decline" class="btn btn-text">
          Only vital cookies
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookie",
  props: {
    accept: Function,
    decline: Function,
  },
  data: function () {
    return { lottieLoaded: false };
  },
  mounted: function () {
    setTimeout(() => {
      const player = document.querySelector("lottie-player");
      player.load(require("@/assets/lottie/cookie.json"));
      this.lottieLoaded = true;
    }, 10);
  },
};
</script>

