import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.$cookies.config('10min')

function convertRefToPathString(ref) {
    let pathSegments = (ref._query) ? (ref._query.path.segments) : ref.path
    if (ref._query && ref._query.filters[0]) {
        ref._query.filters.forEach(filter => {
            pathSegments += filter.field.segments[0] + filter.op.name + filter.field.segments[1]
        })
        if (ref._query.startAt) {
            if (ref._query.startAt.position[0].key) pathSegments += '&startAt=' + ref._query.startAt.position[0].key.path.segments.toString()
        }
        if (ref._query.limit) {
            pathSegments += '&limit=' + ref._query.limit
        }
    }
    let pathString = pathSegments.toString()
    return pathString.replace(/,/g, '/')
}

const setCookie = (key, value) => {
    if (!Vue.$cookies.get(key)) Vue.$cookies.set(key, value)
}

const getCookie = (key) => {
    return Vue.$cookies.get(key)
}

const validCache = (ref) => {
    let pathString = convertRefToPathString(ref)
    let cookieExists = Vue.$cookies.get(pathString)
    if (!cookieExists) Vue.$cookies.set(pathString, 'true')
    return cookieExists
}

export {
    validCache,
    setCookie,
    getCookie
}