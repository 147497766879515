export default {
    name: 'ValBox',
    props: {
        value: Object
    },
    methods: {
        getBadgeClasses: function () {
            if (this.value.incPos === false) {
                return {
                    'badge-danger': this.value.trend > 0,
                    'badge-success': this.value.trend < 0,
                    'badge-normal': this.value.trend === 0 || !this.value.trend,
                }
            } else {
                return {
                    'badge-success': this.value.trend > 0,
                    'badge-danger': this.value.trend < 0,
                    'badge-normal': this.value.trend === 0 || !this.value.trend,
                }
            }

        }
    }
}