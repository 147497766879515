import {
    db,
    GET
} from "@/firebase.js"

let events = {
    armada: 'MpnsP9E3kqShxAugiWvq',
    finance: 'jOvSMpMn46lRxUX25TuE',
    fashion: 'CRsoZHMIDEy3hNfWojom'
}

const Eventer = {
    install(Vue) {
        Vue.observable({
            $event: {}
        })
        Vue.prototype.$getEvent = (id) => {
            return new Promise((resolve) => {
                let eventId = events[id]
                let doc = db.collection('events').doc(eventId)
                GET(doc).then(event => {
                    Vue.prototype.$event = {
                        name: event.data().name,
                        id: event.id,
                        eid: event.data().eid,
                        badges: event.data().badges,
                        title: event.data().title,
                        featuredOrgs: event.data().featured_orgs,
                        popularOrgs: event.data().popular_orgs,
                        featuredBadges: event.data().featured_badges,
                        featuredRanking: event.data().featured_ranking,
                        rankings: event.data().rankings,
                        released: event.data().released,
                        article: event.data().article
                    }
                    resolve('success')
                })
            })
        }
        Vue.prototype.$clearEvent = () => {
            Vue.prototype.$event = {}
        }
    }
}

export default Eventer