import Tooltip from '@/components/Tooltip/Tooltip.vue'
export default {
    name: "Menu",
    components: {
        Tooltip
    },
    data: function () {
        return {
            showTooltip: false,
            tabs: [{
                label: 'Home',
                path: '',
                icon: {
                    type: 'icon',
                    selected: 'home-fill',
                    deselected: 'home-line',
                }
            }, {
                label: 'Badges',
                path: '/badges',
                icon: {
                    type: 'icon',
                    selected: 'compass-fill',
                    deselected: 'compass-line',
                }
            }, {
                label: 'Rankings',
                path: '/rankings',
                icon: {
                    type: 'icon',
                    selected: 'bar-chart-fill',
                    deselected: 'bar-chart-2-line',
                }
            }, {
                label: 'Companies',
                path: '/companies',
                icon: {
                    type: 'icon',
                    selected: 'stack-fill',
                    deselected: 'stack-line',
                }
            }],
            menuType: null,
        }
    },
    methods: {
        setTooltipStatus: function () {
            if (localStorage.getItem('menu') || this.$route.name !== 'Home') return false
            localStorage.setItem('menu', true)
            this.showTooltip = true
        },
        isFacebookApp: function () {
            var ua = navigator.userAgent || navigator.vendor || window.opera;
            return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf('Instagram') > -1);
        },
        menuTypeListener: function () {
            window.addEventListener("resize", this.setMenuType);
        },
        setMenuType: function () {
            if (this.isFacebookApp() || window.innerWidth > 600) this.menuType = 'floating'
            else this.menuType = 'fixed'
        }
    },
    created: function () {
        this.setTooltipStatus()
        this.setMenuType()
        this.menuTypeListener()
    }
};