<template>
  <div>
    <div
      v-if="fullscreen"
      class="w-100 fullscreen-modal-overlay loading-overlay"
    >
      <img
        class="loading-icon"
        src="@/assets/img/Components/Loading/loader.svg"
        alt=""
      />
    </div>
    <div class="loading-box w-100" v-if="!fullscreen">
      <img
        class="loading-icon"
        src="@/assets/img/Components/Loading/loader-dark.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    fullscreen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Loading.scss";
</style>