import ValBox from '@/components/ValBox/ValBox.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'

export default {
    name: 'Cell',
    props: {
        title: String,
        desc: String,
        link: [String, Function],
        value: Object,
        index: Number,
        descMax: {
            type: Number,
            default: 26
        },
        titleMax: {
            type: Number,
            default: 20
        },
        active: Boolean,
        badge: String,
    },
    components: {
        ValBox,
        Tooltip
    },
    data: function () {
        return {
            showTooltip: false
        }
    },
    methods: {
        goToLink: function () {
            if (!this.link) return
            if (typeof this.link === 'string') {
                this.$router.push({
                    path: this.link
                })
            } else {
                this.link()
            }
        },
        setTooltipVisibility: function () {
            if (localStorage.getItem('rankCardTooltip')) return
            this.showTooltip = (this.value && this.index === 0)
        }
    },
    created: function () {
        this.setTooltipVisibility()
    }
}